<template>
	<div>
		<my-page-license-item-list />
	</div>
</template>

<script>
import MyPageLicenseItemList from './MyPageLicenseItemList.vue'

export default {
	components: {
		MyPageLicenseItemList,
	},
	setup() {},
}
</script>
