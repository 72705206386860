<template>
	<div>
		<v-row class="mt-1 mb-2 px-3">
			<h3 style="font-weight: 500">
				<v-icon style="position: relative; bottom: 2px">
					mdi-card-multiple-outline
				</v-icon>
				교육
			</h3>
		</v-row>
		<div class="grid-container">
			<template v-if="items.length > 0">
				<v-card
					class="grid-item"
					v-for="(item, index) in items"
					:key="index"
					style="cursor: pointer; position: relative"
					@click="routeToItemDetail(item)"
				>
					<div class="backdrop d-flex align-center justify-center">
						<v-icon large color="white">mdi-library-outline</v-icon>
					</div>
					<v-card-title class="d-flex flex-column align-start">
						<div class="category">자격증</div>
						<div class="title">{{ item.title }}</div>
						<div class="eventLocation">
							<v-icon small>mdi-map-marker</v-icon>
							{{ item.location }}
						</div>
					</v-card-title>
					<v-card-text style="position: absolute; bottom: 0">
						<v-divider class="my-3" />
						<span class="amount font-weight-bold">
							{{ item.amount | commaFormat }} 원
						</span>
					</v-card-text>
				</v-card>
			</template>
			<template v-else>
				<h4>신청 가능한 교육이 없습니다</h4>
			</template>
		</div>
	</div>
</template>

<script>
import { ref } from '@vue/composition-api'

import dayjs from 'dayjs'

import { useRouter } from '@core/utils'

import MarketItemService from '@/services/MarketItemService'

const MAX_DISPLAY_DAYS = 3

export default {
	components: {},
	setup() {
		const { router } = useRouter()
		const items = ref([])

		const getMarketItems = async () => {
			try {
				const data = await MarketItemService.listMarketItems()
				items.value = data.filter(
					obj =>
						obj.visible === true &&
						dayjs().diff(dayjs(obj.endDate), 'day') < MAX_DISPLAY_DAYS,
				)
			} catch (e) {
				console.error(e)
			}
		}
		getMarketItems()

		const routeToItemDetail = item => {
			router.push({
				name: 'mypage-market-item-order',
				params: { itemId: item.id },
			})
		}

		return {
			items,

			routeToItemDetail,
		}
	},
}
</script>
<style lang="scss" scoped>
.grid-container {
	display: grid;
	gap: 24px;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	grid-auto-rows: 1fr;

	@media screen and (max-width: 1024px) {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}

	@media screen and (max-width: 846px) {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}

	@media (max-width: 594px) {
		grid-template-columns: repeat(1, minmax(0, 1fr));
	}

	.grid-item {
		min-height: 348px;

		.backdrop {
			height: 110px;
			background-color: rgb(34, 34, 34);
		}

		.v-card__title {
			font-size: 16px;

			.title {
				font-weight: 700;
				line-height: 1.3;
			}

			.eventAt,
			.eventLocation,
			.dueDate {
				font-size: 15px;
				color: rgb(94, 95, 97);
				line-height: 19.4px;
				margin-bottom: 2px;
			}
			.dueDate {
				margin-top: 5px;
				margin-bottom: 5px;
			}

			.amount {
				font-size: 16px;
				font-weight: 700;
			}

			.category {
				font-size: 12px;
				margin-bottom: 8px;
				font-weight: 700;
			}
		}

		.amount {
			font-size: 16px;
			font-weight: 700;
		}
	}
}
</style>
